import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { Col, Container, Row } from 'reactstrap';

import SocialLinks from './socialLinks';

import '../assets/scss/components/_introSection.scss';
import btnScouting from '../assets/img/impect_scouting-btn.png';
import btnAnalyse from '../assets/img/impect_analyse-btn.png';
import ipad from '../assets/img/ipad.png';
import packingImage from '../assets/img/packing-image.png';

class IntroSection extends Component {
  constructor(props) {
    super(props);
    this.state = { height: props.height };

    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      window.addEventListener('resize', this.updateDimensions);
    }
  }

  componentWillUnmount() {
    if (typeof window !== `undefined`) {
      window.removeEventListener('resize', this.updateDimensions);
    }
  }

  updateDimensions() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      this.setState({ height: window.innerHeight - 99 });
    }
  }

  render() {
    const { header, subHeader, packingInfo } = this.props;

    return (
      <>
        <section
          className="intro-section position-relative"
          style={{
            height: this.state.height + 'px',
            marginLeft: -15,
            marginRight: -15
          }}
        >
          <div
            className="position-absolute social-links-wrapper"
          >
            <SocialLinks />
          </div>

          <div
            className="position-absolute header-wrapper"
          >
            {header ? (
              <h2 className="header d-block color-impect-blue text-uppercase font-weight-bold">
                {header}
              </h2>
            ) : null}

            {subHeader ? (
              <h2 className="sub-header d-block color-impect-black text-uppercase font-weight-bold">
                {subHeader}
              </h2>
            ) : null}
          </div>

          <div
            className="position-absolute d-md-inline d-none"
            style={{
              bottom: '4rem',
              left: '3rem',
              zIndex: 2
            }}
          >
            <Link to={`/${this.props.activeLangKey}/products/scouting`}>
              <img src={btnScouting} alt="Impect Scouting"/>
            </Link>
          </div>
          <div
            className="position-absolute d-md-inline d-none"
            style={{
              bottom: '4rem',
              right: '3rem',
              zIndex: 2
            }}
          >
            <Link to={`/${this.props.activeLangKey}/products/analysis`}>
              <img src={btnAnalyse} alt="Impect Analyse"/>
            </Link>
          </div>

          {this.props.headerImage ? (
            <Img
              className='img-fluid'
              style={{ position: 'initial' }}
              fixed={this.props.headerImage}
            />
          ) : null}
        </section>
        <section
          className="intro-text-section text-white position-relative"
        >
          <Container>
            <img
              src={ipad}
              alt="Impect Analyse"
              className="ipad-image"
            />
            <div className="text-1">
              <Row>
                <Col md={{ size: 6, offset: 6 }}>
                  <h2>
                    <span>{packingInfo.headerPacking}</span>
                    {packingInfo.header}
                  </h2>
                </Col>
              </Row>

              <Row>
                <Col md={{ size: 5, offset: 7 }}>
                  <p dangerouslySetInnerHTML={{__html: packingInfo.content}} />
                </Col>
              </Row>
            </div>

            <Row>
              <img
                src={packingImage}
                alt="Impect Analyse"
                className="packing-image"
              />
              <Col md={5} className="my-auto text-2">
                <p>{packingInfo.contentTwo}</p>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

IntroSection.defaultProps = {
  header: null,
  subHeader: null,
  headerImage: null,
  activeLangKey: 'de'
};

IntroSection.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  headerImage: PropTypes.string,
  activeLangKey: PropTypes.string
};

export default IntroSection;

