import React, { Component } from 'react'
import 'intl';

import IntroSection from './introSection'
import ProductSection from './productSection'
import Testimonials from './testimonials'

import '../assets/scss/pages/_homePage.scss'

class HomePage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { intro, packingInfo, activeLangKey, products, headerImage, testimonials } = this.props;

    return (
      <div>
        <IntroSection
          header={intro.header}
          subHeader={intro.subHeader}
          activeLangKey={activeLangKey}
          headerImage={headerImage}
          packingInfo={packingInfo}
        />

        <ProductSection
          activeLangKey={activeLangKey}
          content={products}
        />

        {/*<Testimonials
          activeLangKey={activeLangKey}
          items={testimonials}
        />*/}

      </div>
    );
  }
}

export default HomePage;
