import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import HomePage from '../components/homePage';

class IndexPage extends React.Component {
  constructor(props) {
    super(props);

  }

  render() {

    const { data } = this.props;
    const { seo, intro, packingInfo, products, testimonials } = data.homeDeJson;

    return (
      <Layout data={this.props.data} location={this.props.location}>
        <SEO {...seo} />

        <HomePage
          intro={intro}
          headerImage={data.file.childImageSharp.fixed}
          activeLangKey={this.props.pageContext.langKey}
          testimonials={testimonials}
          packingInfo={packingInfo}
          products={products}
        />
      </Layout>
    );
  }
}


export default IndexPage;

export const pageQuery = graphql`
    query PageDeQuery {
        site {
            siteMetadata {
                languages {
                    defaultLangKey
                    langs
                }
            }
        }
        file(relativePath: { eq: "header-bg.jpg" }) {
            childImageSharp {
                fixed(width: 2500, height: 1281 quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        homeDeJson {
            seo {
                title
                description
                lang
                keywords
            }
            intro {
                header
                subHeader
            }
            packingInfo {
                headerPacking
                header
                content
                contentTwo
            }
            products {
                header
                scouting
                analysis
            }
            testimonials {
                name
                job
                blockquote
                image {
                    childImageSharp {
                        fixed(width: 100, height: 100, quality: 100) {
                            ...GatsbyImageSharpFixed_withWebp
                        }
                    }
                }
            }
        }
    }
`;
